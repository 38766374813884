import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { validate } from '../../helper/validateMasterConfiguration';
import { formatConfigurationData } from '../../helper/formatConfigurationData';
import { dateRange } from '../add-masterConfiguration/contants';
import { calculateDayOftheyear } from '../../helper/calculateDayoftheyear';
import { NotificationValue } from '../../helper/variables';

export const handleOnSubmit = async (
    data,
    id,
    checkType,
    setCustomErrors,
    navigate,
    attribute,
    dirtyFields,
    authState,
) => {
    try {
        if (data.date === 29 && new Date() % 4 !== 0)
            toastr.info('Info', 'This run day only applicable for leap years!');
        if (data?.frequency === 'Yearly' && data?.month && data?.date) {
            data.run_day = calculateDayOftheyear(
                new Date(
                    `${dateRange[data?.month]?.order}/${
                        data?.date
                    }/${new Date().getFullYear()}`,
                ),
            );
        }
        let errors = validate(data, checkType, attribute);
        if (Object.values(errors).length >= 1) {
            setCustomErrors(errors);
            return null;
        } else setCustomErrors({});
        let formatedData = formatConfigurationData(data, checkType);
        const request = {
            resource: 'api/masterConfiguration',
            resourceId: id,
        };

        const res = await api.crud.update(request, {
            ...formatedData,
            check_type: checkType,
            dirtyFields: dirtyFields,
            authState,
        });
        if (res.data.status === 200) {
            toastr.success('Success', 'Metrics updated Successfully');
            addNotificationsForAddedMetrics(formatedData, authState);
            navigate('/');
        } else {
            toastr.error('Error', res.data.message);
        }
    } catch (error) {
        toastr.error('Error', error.message);
    }
};
const addNotificationsForAddedMetrics = async (formatedData, authState) => {
    try {
        const request = { resource: 'api/notifications/metrics' };
        const res = await api.crud.create(request, {
            notificationData: {
            notification_type: "metrics",
            notification_value: NotificationValue.METRICS_ACCESS_CHANGED,
            read_status: 0,
            from_user_id: authState?.profile?.user_id,
            },
            metricsData: {
                share_to: formatedData.share_to,
                selected_users: formatedData.selected_user?.map(user => user?.user_id),
                selected_groups: formatedData.selected_group
            }

        });
        if (res.data.status === 200) {
            // toastr.success('Success', 'Notification added Successfully');
        } else {
            toastr.error('Error', res.data.message);
        }
    } catch (error) {
        toastr.error('Error', error.message);
    }
}