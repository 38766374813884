import { useEffect, useState } from 'react';
import api from '../../api';
import { DISTRIBUTION, FRESHNESS, SCHEMA } from '../../helper/variables';
import { toastr } from 'react-redux-toastr';

function useMasterConfiguration() {
    //states
    const [sourceListData, setSourceListData] = useState([]);
    const [datasourceListData, setDatasourceListData] = useState([]);
    const [customErrors, setCustomErrors] = useState({});
    const [sourceListByTableName, setSourceListByTableName] = useState([]);
    const [dateColums, setDateColumns] = useState([]);
    const [checkType, setCheckType] = useState();
    const [attribute, setAttribute] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [tableName, setTableName] = useState('');
    const [globalLoader, setGlobalLoader] = useState(false);
    const [month, setMonth] = useState('');
    const [frequency, setFrequency] = useState('');
    const [loadMinThreshold, setLoadMinThreshold] = useState(false);

    // hooks
    useEffect(() => {
        const fetchSourceList = async () => {
            try {
                const sourceList = await api.sourceList.getSourceList({
                    resource: 'api/sourceList',
                });
                setSourceListData(sourceList.data.data);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchDatasourceList = async () => {
            try {
                const datasourceList = await api.vault.getVaultapi({
                    resource: 'api/vault',
                });
                let temp = [];
                datasourceList?.data?.data.map((item) => {
                    temp.push(item.name);
                });
                setDatasourceListData(temp);
            } catch (error) {
                setIsLoading(false);
                toastr.error('Error', 'Vault Data is not found');
            }
        };
        fetchSourceList();
        fetchDatasourceList();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    //date columns fetch logic
    useEffect(async () => {
        if (tableName && checkType && ![SCHEMA].includes(checkType)) {
            setDateColumns([]);
            const res = await api.sourceList.getDateColumnsByTableName({
                resource: `/api/sourcelist/dateColumns/${tableName}`,
            });
            if (res) {
                setDateColumns(res.data);
            }
        }
    }, [tableName, checkType]);

    //colums fetch logic
    useEffect(() => {
        const fetchSourceListTableName = async () => {
            try {
                if (
                    tableName &&
                    checkType &&
                    ![SCHEMA, FRESHNESS].includes(checkType)
                ) {
                    setGlobalLoader(true);
                    const response =
                        await api.sourceList.getSourceListbyTableName({
                            resource: 'api/sourceList',
                            tableName,
                            stringColOnly: checkType === DISTRIBUTION,
                        });
                    if (response) {
                        setSourceListByTableName(response?.data?.data);
                    }
                    setGlobalLoader(false);
                }
            } catch (error) {
                toastr.error('Error', 'Cannot able to fetch');
            }
        };
        fetchSourceListTableName();
    }, [tableName, checkType]);

    return {
        sourceListByTableName,
        sourceListData,
        datasourceListData,
        dateColums,
        customErrors,
        setCustomErrors,
        tableName,
        setTableName,
        isLoading,
        setIsLoading,
        attribute,
        setAttribute,
        checkType,
        setCheckType,
        globalLoader,
        month,
        setMonth,
        frequency,
        setFrequency,
        loadMinThreshold,
        setLoadMinThreshold,
    };
}

export default useMasterConfiguration;
