import React, { useState } from "react";
import {
  Dropdown,
  Button,
  ListGroup,
  Badge,
  Nav,
  Spinner,
} from "react-bootstrap";
import "./notification.scss";
import ContainerLoader from "../loader/container-loader";
import { generateContentBasedOnType } from "../../helper/variables";
import moment from "moment";

function Notification({
  showNotifications,
  notificationModalRef,
  handleClickonNotificationIcon,
  storeNotifications,
  notificationsIsLoading,
}) {
  const [activeTab, setActiveTab] = useState("all");

  const formatDate = (date) =>
    new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
      .format(new Date(date))
      .replace(/,/g, "");



  const categorizeNotifications = (type) => {
    return storeNotifications
      ?.filter((item) => type === "all" || item.notification_type === type)
      .map((item) => ({
        type: item.notification_type || "all",
        user: item["sender.display_name"],
        date_time: formatDate(item.createdAt),
        time: ConvertData(item.createdAt),
        content: generateContentBasedOnType(item),
      }));
  };
  const formatTime = (date) => {
    return new Date(date); // Convert string to Date object
  };
  const timeAgo = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const diffInDays = now.diff(inputDate, "days");
    const diffInWeeks = now.diff(inputDate, "weeks");
  
    if (diffInDays >= 7 && diffInDays < 31) {
      return `${diffInWeeks} week${diffInWeeks > 1 ? "s" : ""} ago`;
    }
  
    return inputDate.fromNow();
  };
  const TimeAgoComponent = ({ timestamp }) => {
    return <span>{timeAgo(timestamp)}</span>;
  };
  
  const ConvertData = (data) => {
    const formatedDate = formatTime(data); // Convert input to Date object
    return (
      <div>
        <p><TimeAgoComponent timestamp={formatedDate} /></p>
      </div>
    );
  };


  const notifications = {
    all: categorizeNotifications("all"),
    metrics: categorizeNotifications("metrics"),
    role: categorizeNotifications("role"),
    groups: categorizeNotifications("groups"),
    general: categorizeNotifications("general"),
  };

  const renderNotifications = (type) => {
    return notifications[type]?.map((notification, index) => (
      <ListGroup.Item key={index}>
        <div className="w-100 p-2">
          <div className=" d-flex justify-content-between">
            <span>
              <strong style={{ letterSpacing: '0.5px' }}>{notification.user}</strong>
            </span>
            <span>
              <small className="text-muted">{notification.time}</small>
            </span>
          </div>

          <span>{notification.content}</span>
        </div>
      </ListGroup.Item>
    ));
  };

  return (
    <div style={{ position: "relative" }} ref={notificationModalRef}>
      <div
        style={{
          position: "absolute",
          top: "0.1em",
          right: 50,
          width: "40vw",
          background: "#fff",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
          zIndex: 10,
          // height: "75vh",
          padding: "10px",
        }}
      >
        <div className="p-3 d-flex justify-content-between align-items-center">
          <div>
            <h6 className="mb-0"><strong>Your Notifications</strong></h6>
          </div>
          <div>

            <Button variant="link" className="p-0 text-decoration-none markBtn">
              Mark all as read
            </Button>
          </div>
        </div>

        <Nav variant="tabs" className="notificationTabs px-3 mb-1">
          <div className="navTabs">
            {Object.keys(notifications).map((tab) => (
              <Nav.Item key={tab}>
                <Nav.Link
                  active={activeTab === tab}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}

                  <Badge>{notifications[tab]?.length}</Badge>
                </Nav.Link>
              </Nav.Item>
            ))}
          </div>
        </Nav>

        <div>
          {notificationsIsLoading ? (
            <div className="d-flex justify-content-center">
              <ContainerLoader height="51vh" />
            </div>
          ) : (
            <ListGroup
              variant="flush"
              style={{ overflowY: "auto", height: "400px" }}
            >
              {renderNotifications(activeTab)}
            </ListGroup>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notification;
