export const check_types = ['freshness', 'schema', 'volume', 'distribution'];
export const FRESHNESS = 'freshness';
export const SCHEMA = 'schema';
export const VOLUME = 'volume';
export const DISTRIBUTION = 'distribution';
export const GENERAL = 'general';
export const check_types_custom_query = ['freshness', 'volume', 'distribution'];

export const NotificationValue = {
    ROLE_ADMIN: 'adminRole',
    ROLE_VIEWER: 'viewerRole',
    UPDATED_USER_SETTINGS: 'updatedUserSettings',
    ROLE_ADMIN_TO_VIEWER: 'adminToViewer',
    ROLE_VIEWER_TO_ADMIN: 'viewerToAdmin',
    GROUP_USER_ADDED: 'userAddedInGroup',
    GROUP_USER_REMOVED: 'userRemovedFromGroup',
    GROUP_CREATED: 'groupCreated',
    GROUP_DELETED: 'groupsDeleted',
    METRICS_ACCESS_GIVEN: 'metricsAccessGiven',
    METRICS_ACCESS_REVOKED: 'metricsAccessRevoked',
    METRICS_ACCESS_CHANGED: 'metricsAccessChanged',
    GENERAL: 'general',
};

export const NotificationTypes = {
    ROLE: 'role',
    GROUP: 'group',
    METRICS: 'metrics',
    GENERAL: 'general',
};
export const generateContentBasedOnType = (item) => {
    switch (item.notification_value) {
        case NotificationValue.ROLE_ADMIN:
            return `Your role has been changed to Admin, granting you full administrative access.`;
        case NotificationValue.ROLE_VIEWER:
            return `Your role has been changed to Viewer, allowing you read-only access.`;
        case NotificationValue.UPDATED_USER_SETTINGS:
            return `Your settings have been updated successfully.`;
        case NotificationValue.ROLE_ADMIN_TO_VIEWER:
            return `Your role has been downgraded from Admin to Viewer, restricting your administrative privileges.`;
        case NotificationValue.ROLE_VIEWER_TO_ADMIN:
            return `Your role has been upgraded from Viewer to Admin, granting you administrative privileges.`;
        case NotificationValue.GROUP_USER_ADDED:
            return `You have been added to a group.`;
        case NotificationValue.GROUP_USER_REMOVED:
            return `You have been removed from a group.`;
        case NotificationValue.GROUP_CREATED:
            return `You have created a new group.`;
        case NotificationValue.GROUP_DELETED:
            return `A group you were part of has been deleted.`;
        case NotificationValue.METRICS_ACCESS_GIVEN:
            return `You have been granted access to view and analyze metrics.`;
        case NotificationValue.METRICS_ACCESS_REVOKED:
            return `Your access to view and analyze metrics has been revoked.`;
        default:
            return `You have a new notification. Please check for details.`;
    }
};