import { useState, useEffect, useCallback } from 'react';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Multiselect } from 'react-widgets';
import { Button, Container, Spinner } from 'react-bootstrap';
import { TiUserAdd } from 'react-icons/ti';
import { FaUsers } from 'react-icons/fa';
import { FaTrashAlt } from 'react-icons/fa';
import { Placeholder, Row, Col } from 'react-bootstrap';
import { NotificationTypes, NotificationValue } from '../../helper/variables';
import { useSelector } from 'react-redux';

// import "react-widgets/styles.css";

const GroupUsers = ({ show, onClose, id }) => {
    const [loading, setLoading] = useState(true);
    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [activeSection, setActiveSection] = useState(null);
    const [data, setData] = useState({ users: [], group: {}, nonUsers: [] });
    const [addUsers, setAddUsers] = useState([]);
    const [removeUsers, setRemoveUsers] = useState([]);

    const authState = useSelector((state) => state.authUser);

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.user.getUsersByGroupId(id);
            if (response.data.status === 200) {
                setData({
                    users: response.data.data.user,
                    group: response.data.data.groups,
                    nonUsers: response.data.data.nonUser,
                });
            } else {
                toastr.error('Error', 'Failed to fetch users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setLoading(false), 1500);
    }, [id]);

    useEffect(() => {
        if (show) {
            fetchUsers();
            setAddUsers([]);
            setRemoveUsers([]);
            // const timer = setTimeout(() => setLoading(false), 1500);
            // return () => clearTimeout(timer);
        }
    }, [show, fetchUsers]);

    if (!show) return null;

    // const CustomItem = ({ item }) => (
    //     <span>{item.display_name} ({item.email})</span>
    // );

    const adminUsers = data.users.filter(
        (user) => user['role.role_name'] === 'Admin',
    );
    const nonAdminUsers = data.users.filter(
        (user) => user['role.role_name'] !== 'Admin',
    );

    const availableUsers = data.nonUsers || [];

    // const handleAddUser = () => {
    //     if(removeMultiselect) setRemoveMultiselect(false);
    //     setAddMultiselect(prevState => !prevState);
    // };

    // const handleRemoveUser = ()=>{
    //     if(addMultiselect===true) setAddMultiselect(false);
    //     setRemoveMultiselect(prevState => !prevState)
    // }

    const handleAddUserSelect = (selected) => {
        setAddUsers(selected);
    };

    const handleRemoveUserSelect = (selected) => {
        setRemoveUsers(selected);
    };

    const handleCancel = () => {
        setAddUsers([]);
        setRemoveUsers([]);
        setActiveSection(null);
    };

    const invokeNotificationHandler = async (data, userIds) => {
        const formattedData = userIds.map((element) => {
            return {
                ...data,
                user_id: element,
                from_user_id: authState?.profile?.user_id,
            };
        });

        try {
            const res = await api.userNotification.addNotification(
                formattedData,
            );

            console.log('Notification Response', res);
        } catch (error) {
            console.log('Error in notification handler', error);
        }
    };

    const handleAddSubmit = async () => {
        if (addUsers.length === 0) {
            alert('Please select at least one user.');
            return;
        }
        setIsAdding(true);
        const requestData = {
            groupId: data.group.group_id,
            userIds: addUsers.map((user) => user.user_id),
        };

        try {
            const response = await api.user.addUsersToGroup(requestData);
            if (response.data.status === 200) {
                toastr.success('Success', `${response.data.message}`);
                fetchUsers();
                invokeNotificationHandler(
                    {
                        notification_type: NotificationTypes.GROUP,
                        notification_value: `${NotificationValue.GROUP_USER_ADDED}:${data.group.group_name}`,
                    },
                    requestData.userIds,
                );
            } else {
                toastr.error('Error', 'Failed to Add Users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setIsAdding(false), 1500);
        handleCancel();
    };

    const handleRemoveSubmit = async () => {
        if (removeUsers.length === 0) {
            alert('Please select at least one user.');
            return;
        }
        setIsRemoving(true);
        const requestData = {
            groupId: data.group.group_id,
            userIds: removeUsers.map((user) => user.user_id),
        };

        try {
            const response = await api.user.deleteUsersFromGroup(requestData);
            if (response.data.status === 200) {
                toastr.success('Success', `${response.data.message}`);
                fetchUsers();
                invokeNotificationHandler(
                    {
                        notification_type: NotificationTypes.GROUP,
                        notification_value: `${NotificationValue.GROUP_USER_REMOVED}:${data.group.group_name}`,
                    },
                    requestData.userIds,
                );
            } else {
                toastr.error('Error', 'Failed to Remove Users');
            }
        } catch (error) {
            toastr.error('Error', error.message);
        }
        setTimeout(() => setIsRemoving(false), 1500);
        handleCancel();
    };

    return (
        <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex justify-content-center align-items-center">
            <div
                className="bg-white px-4 py-4 rounded shadow-lg"
                style={{
                    width: '80%',
                    maxHeight: '95vh',
                    overflow: 'hidden',
                }}
            >
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">
                        <FaUsers />
                        &nbsp;Group Users{' '}
                    </h4>
                    <button
                        className="btn-close"
                        onClick={() => {
                            handleCancel();
                            onClose();
                        }}
                    ></button>
                </div>

                {loading ? (
                    <Row
                        className="row border p-3 rounded mb-3 bg-light align-items-center"
                        style={{
                            //  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            height: '100px',
                        }}
                    >
                        {[...Array(5)].map((e, i) => (
                            <Col>
                                <Placeholder animation="glow">
                                    <Placeholder
                                        xs={12}
                                        className="rounded-1"
                                        size="lg"
                                    />
                                </Placeholder>
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <Container>
                        <div
                            className="row p-2 border rounded mb-3 bg-light align-items-center"
                            style={{
                                boxShadow:
                                    'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                height: '100px',
                            }}
                        >
                            <div className="d-flex justify-content-between col-9">
                                <div className="d-flex flex-column me-4">
                                    <strong>Name:</strong>
                                    <span>{data.group.group_name}</span>
                                </div>
                                <div className="d-flex flex-column me-4">
                                    <strong>Description:</strong>
                                    <span>{data.group.description}</span>
                                </div>
                                <div className="d-flex flex-column me-4">
                                    <strong>Created By:</strong>
                                    <span>{data.group.creator_name}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <strong>Status:</strong>
                                    <span
                                        className={`badge ${
                                            data.group.is_active
                                                ? 'bg-success'
                                                : 'bg-danger'
                                        }`}
                                    >
                                        {data.group.is_active
                                            ? 'Active'
                                            : 'Inactive'}
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex col-3 align-items-center justify-content-center col-3">
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        setActiveSection(
                                            activeSection === 'add'
                                                ? null
                                                : 'add',
                                        );
                                    }}
                                >
                                    <TiUserAdd />
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setActiveSection(
                                            activeSection === 'remove'
                                                ? null
                                                : 'remove',
                                        );
                                    }}
                                >
                                    <FaTrashAlt />
                                </button>
                            </div>
                        </div>{' '}
                    </Container>
                )}
                {activeSection && (
                    <div className="mb-3">
                        <label className="mb-2">
                            <strong>
                                {activeSection === 'add'
                                    ? 'Add Users'
                                    : 'Remove Users'}
                                :
                            </strong>
                        </label>
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div style={{ width: '80%' }}>
                                <Multiselect
                                    data={
                                        activeSection === 'add'
                                            ? availableUsers
                                            : nonAdminUsers
                                    }
                                    value={
                                        activeSection === 'add'
                                            ? addUsers
                                            : removeUsers
                                    }
                                    textField={'display_name'}
                                    onChange={
                                        activeSection === 'add'
                                            ? handleAddUserSelect
                                            : handleRemoveUserSelect
                                    }
                                    placeholder="Search by name or email..."
                                    filter={(user, searchTerm) =>
                                        user.display_name
                                            .toLowerCase()
                                            .includes(
                                                searchTerm.toLowerCase(),
                                            ) ||
                                        user.email
                                            .toLowerCase()
                                            .includes(searchTerm.toLowerCase())
                                    }
                                />
                            </div>
                            <div
                                style={{ width: '20%' }}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Button
                                    variant={
                                        (activeSection === 'add'
                                            ? isAdding
                                            : isRemoving) ||
                                        (activeSection === 'add'
                                            ? addUsers.length === 0
                                            : removeUsers.length === 0)
                                            ? 'secondary'
                                            : activeSection === 'add'
                                            ? 'success'
                                            : 'danger'
                                    }
                                    onClick={
                                        activeSection === 'add'
                                            ? handleAddSubmit
                                            : handleRemoveSubmit
                                    }
                                    disabled={
                                        (activeSection === 'add'
                                            ? isAdding
                                            : isRemoving) ||
                                        (activeSection === 'add'
                                            ? addUsers.length === 0
                                            : removeUsers.length === 0)
                                    }
                                    style={{ width: '100px' }}
                                >
                                    {(
                                        activeSection === 'add'
                                            ? isAdding
                                            : isRemoving
                                    ) ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                        />
                                    ) : (
                                        'Confirm'
                                    )}
                                </Button>
                                <Button
                                    className="ms-2"
                                    variant="secondary"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    style={{
                        maxHeight: '60vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    {loading ? (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ height: '50vh' }}
                        >
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="text-primary text-center">
                                    Admins
                                </h5>
                                {adminUsers.length > 0 ? (
                                    <div
                                        style={{
                                            maxHeight: '50vh',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <ul className="list-group mb-3">
                                            {adminUsers.map((user, index) => (
                                                <li
                                                    key={index}
                                                    className="list-group-item d-flex justify-content-between align-items-center"
                                                >
                                                    <div>
                                                        <strong>
                                                            {user.display_name}
                                                        </strong>{' '}
                                                        <br />
                                                        <small>
                                                            {user.email}
                                                        </small>
                                                    </div>
                                                    <span
                                                        className={`badge ${
                                                            user.is_active
                                                                ? 'bg-success'
                                                                : 'bg-danger'
                                                        }`}
                                                    >
                                                        {user.is_active
                                                            ? 'Active'
                                                            : 'Inactive'}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <p className="text-muted text-center">
                                        No Admin users found.
                                    </p>
                                )}
                            </div>

                            <div className="col-md-6">
                                <h5 className="text-secondary text-center">
                                    Non-Admins
                                </h5>
                                {nonAdminUsers.length > 0 ? (
                                    <div
                                        style={{
                                            maxHeight: '50vh',
                                            overflowY: 'auto',
                                        }}
                                        className="mb-2"
                                    >
                                        <ul className="list-group">
                                            {nonAdminUsers.map(
                                                (user, index) => (
                                                    <li
                                                        key={index}
                                                        className="list-group-item d-flex justify-content-between align-items-center"
                                                    >
                                                        <div>
                                                            <strong>
                                                                {
                                                                    user.display_name
                                                                }
                                                            </strong>{' '}
                                                            <br />
                                                            <small>
                                                                {user.email}
                                                            </small>
                                                        </div>
                                                        <span
                                                            className={`badge ${
                                                                user.is_active
                                                                    ? 'bg-success'
                                                                    : 'bg-danger'
                                                            }`}
                                                        >
                                                            {user.is_active
                                                                ? 'Active'
                                                                : 'Inactive'}
                                                        </span>
                                                    </li>
                                                ),
                                            )}
                                        </ul>
                                    </div>
                                ) : (
                                    <p className="text-muted text-center">
                                        No Non-Admin users found.
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default GroupUsers;
