import ApiService from './service';
import * as http from './http';

export class UserNotificationService extends ApiService {
    async addNotification(data) {
        if (data && typeof data === 'object' && !Array.isArray(data)) {
            data = [data];
        }
        if (Array.isArray(data)) {
            data = data.map((item) => {
                if (!item.userId) {
                    return {
                        ...item,
                        read_status: 0,
                    };
                }
                return item;
            });
        }

        const url = `${this.apiDomain}/api/notifications`;
        return http
            .post(url, data, this.store)
            .catch((err) => console.log(err));
    }

    async getNotificationsByUserId(userId) {
        const url = `${this.apiDomain}/api/notifications/${userId} `;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async getNotificationsByUserIdAndType(userId, type) {
        const url = `${this.apiDomain}api/notifications${userId}/${type} `;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }
}
