import ApiService from './service';
import * as http from './http';

export class CustomQueryService extends ApiService {

    async getQueryResponse(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        let response = http.post(url, data, this.store);
        return response;
    }

}